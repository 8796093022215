import * as React from "react";

const HOLIDAYS = [
  { name: "Canada Day", hours: "3:00PM - 9:00PM" },
  { name: "Christmas Eve", hours: "11:30AM - 8:00PM" },
  { name: "Christmas Day", hours: "Closed" },
  { name: "Boxing Day", hours: "Closed" },
  {
    name: "New Years Eve",
    hours: (
      <span>
        <strong>Dine in:</strong> 11:30AM - 4:00PM
        <br />
        <br />
        <strong>Takeout:</strong> 11:30AM - 10:00PM
      </span>
    ),
  },
  { name: "New Years Day", hours: "11:30PM - 9:00PM" },
  { name: "Family Day", hours: "3:00PM - 8:00PM" },
  { name: "Good Friday", hours: "3:00PM - 9:00PM" },
  { name: "Mother's Day", hours: "12:00PM - 8:00PM" },
  { name: "Victoria Day", hours: "3:00PM - 8:00PM" },
  { name: "Father's Day", hours: "12:00PM - 8:00PM" },
  { name: "Labour Day", hours: "3:00PM - 8:00PM" },
  { name: "Thanksgiving", hours: "3:00PM - 8:00PM" },
  { name: "Remembrance Day", hours: "3:00PM - 8:00PM" },
];

export default HOLIDAYS;
