import "./App.css";
import React, { useRef, useEffect, useState } from "react";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
// import InfoModal from "./components/InfoModal";
// import { useInfoModalState } from "./hooks/useInfoModalState";

const DEFAULT_BANNER_PADDING = 56;

const App = () => {
  const menuRef = useRef(null);
  const hoursRef = useRef(null);

  const menuRefs = { Menu: menuRef, Hours: hoursRef };
  const bannerRef = useRef<null | { clientHeight: number }>(null);
  const [topPadding, setTopPadding] = useState(DEFAULT_BANNER_PADDING);
  // const { isOpen, handleClose } = useInfoModalState();

  useEffect(() => {
    setTopPadding(bannerRef.current?.clientHeight || DEFAULT_BANNER_PADDING);
  }, [bannerRef]);

  return (
    <>
      <Banner
        bannerRef={bannerRef}
        text="Takeout and catering available. No delivery, open for dine in."
        bgColor="black"
        textColor="white"
      />
      <div style={{ paddingTop: `${topPadding}px` }} className="App">
        <Navigation menuRefs={menuRefs} />
        <Home menuRefs={menuRefs} />
        <Footer />
        {/* <InfoModal
          isOpen={isOpen}
          handleClose={handleClose}
          title="We are currently closed."
          text="From March 4th  - April 8th"
        /> */}
      </div>
    </>
  );
};

export default App;
