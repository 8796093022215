import React from "react";

interface BannerProps {
  text: string | JSX.Element;
  textColor: string;
  bgColor: string;
  bannerRef: React.MutableRefObject<any>;
}

const useStyles = {
  bannerContainer: {},
};

const Banner = ({ text, textColor, bgColor, bannerRef }: BannerProps) => {
  const classes = useStyles;

  return (
    <div
      ref={bannerRef}
      className="banner"
      style={{
        color: textColor,
        backgroundColor: bgColor,
        ...classes.bannerContainer,
      }}
    >
      {text}
    </div>
  );
};

export default Banner;
