import React from "react";
import NavigationIcon from "./NavigationIcon";
import skip_logo from "../images/skip_logo.svg";

const useStyles = {
  navContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  navTitle: {
    margin: "22px 8px 0px 8px",
    textAlign: "center" as "center",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap" as "wrap",
  },
};

interface NavigationProps {
  menuRefs: {
    Menu: React.MutableRefObject<any>;
    Hours: React.MutableRefObject<any>;
  };
}

const icons = [
  {
    img: (
      <img
        style={{ height: "26.5px" }}
        src={skip_logo}
        alt="skip the dishes logo"
      />
    ),
    label: "Order",
  },
  { icon: "🕦", label: "Hours" },
  { icon: "📞", label: "Phone" },
  { icon: "📍", label: "Location" },
];

const Navigation = ({ menuRefs }: NavigationProps) => {
  const classes = useStyles;

  return (
    <div className="navContainer" style={classes.navContainer}>
      <div className="navContainer-inside">
        <h1 style={classes.navTitle}>Tan's Family Restaurant</h1>
        <div style={classes.iconsContainer}>
          {icons.map((icon) => (
            <NavigationIcon key={icon.label} menuRefs={menuRefs} {...icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
