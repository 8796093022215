import React from "react";

const useStyles = {
  iconContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  iconLabel: {
    margin: "6px",
    fontSize: "25px",
  },
};

interface IconProps {
  menuRefs?: {
    Menu: React.MutableRefObject<any>;
    Hours: React.MutableRefObject<any>;
  };

  icon?: string;
  img?: JSX.Element;
  label: string;
}

const Icon = ({ menuRefs, icon, label, img }: IconProps) => {
  const phoneNumbers = ["902-462-8267", "902-462-8268"];
  const classes = useStyles;
  return (
    <button
      style={classes.iconContainer}
      onClick={() => {
        //@ts-ignore
        if (menuRefs?.[label]?.current) {
          // @ts-ignore
          menuRefs?.[label]?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (label === "Phone") {
          const randIndex = Math.floor(Math.random() * 2);
          const randPhone = phoneNumbers[randIndex];
          window.open(`tel:${randPhone}`, "_blank");
        } else if (label === "Location") {
          window.open(
            "https://www.google.com/maps/place/Tans+Family+Restaurant/@44.6698234,-63.5022806,17z/data=!3m2!4b1!5s0x4b5a2483d98adcaf:0x3333a570b487598c!4m5!3m4!1s0x4b5a2481689265af:0x223664e6be123e75!8m2!3d44.6698234!4d-63.5000919",
            "_blank"
          );
        } else if (label === "Order") {
          window.open(
            "https://www.skipthedishes.com/tans-family-restaurant?utm_source=google&utm_medium=organic&utm_campaign=gpa",
            "_blank"
          );
        }
      }}
      className="iconContainer"
    >
      {img && img}
      <span
        className="emoji"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
      >
        {icon}
      </span>
      {label && <p style={classes.iconLabel}>{label}</p>}
    </button>
  );
};

export default Icon;
