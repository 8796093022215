import React from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import HOLIDAYS from "../constants/holidays";

interface HoursCardProps {
  menuRefs: {
    Menu: React.MutableRefObject<any>;
    Hours: React.MutableRefObject<any>;
  };
}

const useStyles = {
  card: {
    display: "flex",
    width: "100%",
    maxWidth: "1000px",
    padding: "8px",
    flexDirection: "column" as "column",
    zIndex: 2,
    margin: "24px 0px",
  },
};

const HoursCard = ({ menuRefs }: HoursCardProps) => {
  const classes = useStyles;
  return (
    <Paper ref={menuRefs["Hours"]} elevation={2} style={classes.card}>
      <h2 className={"cardTitle"}>Regular Hours</h2>
      <Divider />
      <h3 className="hoursText">
        <span className="day">Monday - Saturday</span>
        <span className="hours">11:30AM – 9:00PM</span>
      </h3>
      <Divider />
      <h3 className="hoursText">
        <span className="day">Sunday</span>
        <span className="hours">3:00PM – 8:00PM</span>
      </h3>
      <Divider />
      <h2 className={"cardTitle"}>Holiday Hours</h2>
      {HOLIDAYS.map((holiday) => (
        <>
          <Divider />
          <h3 className="hoursText">
            <span className="day">{holiday.name}</span>
            <span className="hours">{holiday.hours}</span>
          </h3>
        </>
      ))}
    </Paper>
  );
};

export default HoursCard;
