import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

const useStyles = {
  footerContainer: {
    width: "100%",
    height: "150px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    marginTop: "16px",
  },
  line: {
    display: "flex",
    alignItems: "center",
  },
  phoneNumber: {
    margin: "4px",
  },
};

const Footer = () => {
  const classes = useStyles;
  return (
    <div style={classes.footerContainer}>
      <Divider style={{ width: "100%", marginBottom: "14px" }} />
      <div style={classes.line}>
        <div style={{ width: "50%", height: "100%", textAlign: "center" }}>
          <Button
            style={{ color: "black", width: "100%", height: "100%" }}
            href="https://www.google.com/maps/place/Tans+Family+Restaurant/@44.6698234,-63.5022806,17z/data=!3m2!4b1!5s0x4b5a2483d98adcaf:0x3333a570b487598c!4m5!3m4!1s0x4b5a2481689265af:0x223664e6be123e75!8m2!3d44.6698234!4d-63.5000919"
            target="_blank"
            variant="text"
          >
            <span
              style={{ marginRight: "8px" }}
              className="emoji"
              role="img"
              aria-label={"phone"}
              aria-hidden={"true"}
            >
              📍
            </span>
            <p>900 Cole Harbour Rd, Dartmouth, NS B2V 2J5</p>
          </Button>
        </div>
        <div
          style={{
            borderLeft: "1px solid gray",
            width: "50%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <Button
            style={{ color: "black", width: "100%", height: "37.5%" }}
            href="tel:902-462-8267"
            target="_blank"
            variant="text"
          >
            <span
              style={{ marginRight: "8px" }}
              className="emoji"
              role="img"
              aria-label={"phone"}
              aria-hidden={"true"}
            >
              📞
            </span>
            <p>902-462-8267</p>
          </Button>
          <p style={{ margin: "0px", height: "15%" }}>Or</p>
          <Button
            style={{ color: "black", width: "100%", height: "37.5%" }}
            href="tel:902-462-8268"
            target="_blank"
            variant="text"
          >
            <span
              style={{ marginRight: "8px" }}
              className="emoji"
              role="img"
              aria-label={"phone"}
              aria-hidden={"true"}
            >
              📞
            </span>
            <p style={classes.phoneNumber}>902-462-8268</p>
          </Button>
        </div>
      </div>

      <div style={{ ...classes.line, opacity: "0.5 " }}>
        <span
          style={{ marginRight: "8px" }}
          className="emoji"
          role="img"
          aria-label={"phone"}
          aria-hidden={"true"}
        >
          ©
        </span>
        <p>Tan's Family Restaurant</p>
      </div>
    </div>
  );
};

export default Footer;
