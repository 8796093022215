import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import menu1 from "../../images/menu1.png";
import menu2 from "../../images/menu2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Divider from "@mui/material/Divider";
import MenuDialog from "./MenuDialog";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface MenuCardProps {
  menuRefs: {
    Menu: React.MutableRefObject<any>;
    Hours: React.MutableRefObject<any>;
  };
}

const useStyles = {
  card: {
    display: "flex",
    width: "100%",
    maxWidth: "1000px",
    padding: "8px",
    flexDirection: "column" as "column",
    zIndex: 2,
    margin: "24px 0px",
  },
  image: {
    width: "100%",
    height: "auto",
    cursor: "pointer",
  },
};

const MenuCard = ({ menuRefs }: MenuCardProps) => {
  const classes = useStyles;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onClose = () => {
    setIsMenuOpen(false);
  };

  const onOpen = () => {
    setIsMenuOpen(true);
  };

  return (
    <>
      <Paper ref={menuRefs["Menu"]} elevation={2} style={classes.card}>
        <h2 className={"cardTitle"}>Menu</h2>
        <Divider />
        <div>
          <Slider
            className="slider"
            draggable={true}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
            nextArrow={<MenuNextArrow />}
            prevArrow={<MenuPrevArrow />}
            arrows={!isMobile}
          >
            <div>
              <img
                alt={"menu"}
                onClick={isMobile ? onOpen : () => {}}
                style={classes.image}
                src={menu1}
              />
            </div>
            <div>
              <img
                alt={"menu"}
                onClick={isMobile ? onOpen : () => {}}
                style={classes.image}
                src={menu2}
              />
            </div>
          </Slider>
        </div>
        {isMobile && (
          <p className={"cardDescription"}>
            Click to view full menu, swipe for next page.
          </p>
        )}
      </Paper>
      <MenuDialog isOpen={isMenuOpen} onClose={onClose} />
    </>
  );
};

const MenuNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 10, right: "10px" }}
    >
      <IconButton style={{ backgroundColor: "#cd071e" }} onClick={onClick}>
        <KeyboardArrowRightIcon style={{ color: "white" }} />
      </IconButton>
    </div>
  );
};
const MenuPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 10, left: "-10px" }}
    >
      <IconButton style={{ backgroundColor: "#cd071e" }} onClick={onClick}>
        <KeyboardArrowLeftIcon style={{ color: "white" }} />
      </IconButton>
    </div>
  );
};

export default MenuCard;
