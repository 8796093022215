import React from "react";
import Dialog from "@mui/material/Dialog";
import menu1 from "../../images/menu1.png";
import menu2 from "../../images/menu2.png";
import { DialogTitle, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface MenuDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = {
  image: {
    width: "calc(100vw - 48px)",
    height: "auto",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
  },
  title: {
    flexGrow: 1,
  },
  closeIcon: {
    position: "absolute" as "absolute",
    right: "0px",
  },
};

const MenuDialog = ({ isOpen, onClose }: MenuDialogProps) => {
  const classes = useStyles;
  return (
    <Dialog fullScreen={true} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <div style={classes.titleContainer}>
          <h2 style={classes.title} className="cardTitle">
            Menu
          </h2>
          <IconButton onClick={onClose} style={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <img alt={"menu"} style={classes.image} src={menu1} />
        <img alt={"menu"} style={classes.image} src={menu2} />
      </DialogTitle>
    </Dialog>
  );
};

export default MenuDialog;
