import React from "react";
import HoursCard from "./HoursCard";
import MenuCard from "./Menu/MenuCard";

const useStyles = {
  homeContainer: {
    margin: "14px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
};

interface HomeProps {
  menuRefs: {
    Menu: React.MutableRefObject<any>;
    Hours: React.MutableRefObject<any>;
  };
}

const Home = ({ menuRefs }: HomeProps) => {
  const classes = useStyles;

  return (
    <main className="homeContainer" style={classes.homeContainer}>
      <MenuCard menuRefs={menuRefs} />
      <HoursCard menuRefs={menuRefs} />
    </main>
  );
};

export default Home;
